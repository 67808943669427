import APIService from './APIService';

export default {
  getConciliacoes() {
    return APIService.apiCall().get(
      '/conciliacoes-automaticas-bdgd-ais/get-conciliacoes'
    );
  },
  deletar(id) {
    return APIService.apiCall().get(
      `/conciliacoes-automaticas-bdgd-ais/deletar/${id}`
    );
  },
  deletarPorStatus(status) {
    return APIService.apiCall().get(
      `/conciliacoes-automaticas-bdgd-ais/deletar-por-status/${status}`
    );
  }
};
