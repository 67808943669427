<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <conciliacoes-automaticas-bdgd-ais-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getConciliacoes()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConciliacoesAutomaticasBdgdAisService from '@/services/ConciliacoesAutomaticasBdgdAisService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],

  components: {
    ConciliacoesAutomaticasBdgdAisDataTable: () =>
      import(
        '@/components/power-ups/conciliacoes-automaticas/ConciliacoesAutomaticasBdgdAisDataTable.vue'
      )
  },

  data: () => ({
    items: [],
    loading: false
  }),

  mounted() {
    this.refreshData(this.getConciliacoes);
  },

  methods: {
    getConciliacoes() {
      this.items = [];
      this.loading = true;
      ConciliacoesAutomaticasBdgdAisService.getConciliacoes()
        .then((response) => {
          this.items = response.data.data;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
